import firebase from "firebase/app";
import startOfDay from "date-fns/start_of_day";

export const createTasksCollectionRef = () =>
  firebase.firestore().collection("tasks");
export const createUserTasksCollectionQuery = userId =>
  createTasksCollectionRef().where("userId", "==", userId);

export const createUnfinishedTasksRef = userId => {
  const tasksRef = createUserTasksCollectionQuery(userId);
  return tasksRef.where("finishedAt", "==", null);
};
export const createRecentlyFinishedTasksRef = userId => {
  const tasksRef = createUserTasksCollectionQuery(userId);
  return tasksRef.where(
    "finishedAt",
    ">",
    startOfDay(new Date()).toISOString()
  );
};

export const createTaskRef = id =>
  id ? createTasksCollectionRef().doc(id) : createTasksCollectionRef().doc();

export const createTemplatesCollectionRef = () =>
  firebase.firestore().collection("templates");
export const createUserTemplatesCollectionQuery = userId =>
  createTemplatesCollectionRef().where("userId", "==", userId);
export const createTemplateRef = id =>
  id
    ? createTemplatesCollectionRef().doc(id)
    : createTemplatesCollectionRef().doc();
