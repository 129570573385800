import React, { Component } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { ActionButton } from "./taskListItem/styles";

const Root = styled.div`
  && {
    padding: 1rem 1rem 0.5rem 1rem;
  }
`;

const AddTaskButton = styled(ActionButton)`
  && {
    margin-left: 0.5rem;
  }
`;

export class Head extends Component {
  render() {
    const { title, toggleTaskInput } = this.props;
    return (
      <Root>
        <Typography variant="h6">
          {title}
          <AddTaskButton
            onClick={toggleTaskInput}
            aria-label={`Add task to ${title}`}
          >
            <AddIcon />
          </AddTaskButton>
        </Typography>
      </Root>
    );
  }
}
