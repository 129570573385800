import React, { Fragment } from "react";
import MaterialAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { AuthActions } from "./AuthActions";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
});

const NavLink = styled(Button).attrs({ component: Link })`
  && {
    ${({size}) => size === "large" ? "font-size: 1.25rem" : ""}
    color: white;
  }
`;

const NavLinks = () => (
  <Fragment>
    <NavLink to="/moods">
      Moods
    </NavLink>
  </Fragment>
);

function SearchAppBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <MaterialAppBar position="absolute">
        <Toolbar>
          <NavLink
            to="/"
            size="large"
          >
            Todee
          </NavLink>
          <NavLinks/>
          <div className={classes.grow} />
          <AuthActions />
        </Toolbar>
      </MaterialAppBar>
    </div>
  );
}

const AppBar = withStyles(styles)(SearchAppBar);

export { AppBar };
