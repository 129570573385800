import React from "react";
import { connect } from "react-redux";
import { Button, FormHelperText } from "@material-ui/core";
import { Form } from "./Form";
import { AuthFormWrapper, FieldDiv, FormTextField } from "./styles";

const _Register = ({ formErrorMessage, register }) => (
  <AuthFormWrapper>
    <Form
      onSubmit={e => {
        e.preventDefault();
        register({
          email: e.target.email.value,
          password: e.target.password.value
        });
      }}
    >
      <FieldDiv>
        <FormTextField type="email" placeholder="Email" name="email" />
      </FieldDiv>
      <FieldDiv>
        <FormTextField type="password" placeholder="Password" name="password" />
      </FieldDiv>
      {formErrorMessage && (
        <FieldDiv>
          <FormHelperText>{formErrorMessage}</FormHelperText>
        </FieldDiv>
      )}
      <FieldDiv>
        <Button type="submit">Register</Button>
      </FieldDiv>
    </Form>
  </AuthFormWrapper>
);

const dispatchActions = {
  register: loginDetails => ({
    type: "REGISTER",
    loginDetails
  })
};

const mapState = state => ({
  formErrorMessage: null //formErrorMessage(state)
});

const Register = connect(
  mapState,
  dispatchActions
)(_Register);

export { Register };
