import { combineReducers } from "redux";
import orderBy from "lodash.orderby";
import { isToday, parse } from "date-fns";
import { templatesData } from "./templates";
import { moods } from "./moods";

const tasksForQuery = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_TASK":
      const deletedId = action.id;
      const { [deletedId]: deletedValue, ...remainingTasks } = state;
      return remainingTasks;
    default:
      return state;
  }
};

const defaultTasksState = { overrides: {} };
const tasks = (state = defaultTasksState, action) => {
  switch (action.type) {
    case "ADD_TASK":
    case "UPDATE_TASK":
      let { task } = action;
      const { overrides, ...confirmedTasks } = state;
      const previousVersion = Object.values(confirmedTasks)
        .map(query => query[task.id])
        .filter(t => !!t)[0];
      if (previousVersion) {
        task = { ...previousVersion, ...task };
      }
      task = {
        ...task,
        meta: { unconfirmed: true }
      };
      return {
        ...state,
        overrides: {
          ...overrides,
          [task.id]: task
        }
      };
    case "GET_TASKS_RESPONSE":
      return {
        ...state,
        [action.query]: { ...action.tasks },
        ...defaultTasksState
      };
    default:
      const coreQueries = Object.keys(state).reduce(
        (mem, query) => ({
          ...mem,
          [query]: tasksForQuery(state[query], action)
        }),
        {}
      );
      return {
        ...coreQueries,
        ...defaultTasksState
      };
  }
};

const templates = (state = {}, action) => {
  switch (action.type) {
    case "GET_TEMPLATES_RESPONSE":
      return action.templates;
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case "USER_FOUND":
      return action.user;
    default:
      return state;
  }
};

const loadingActions = [
  "USER_FOUND",
  "GET_TEMPLATES_RESPONSE",
  "GET_TASKS_RESPONSE"
];

const waitingFor = (state = loadingActions, action) => {
  switch (action.type) {
    case "USER_NOT_FOUND":
      return loadingActions; // All these actions mean everything needs to be fetched again
    case "LOGIN":
    case "LOGOUT":
    case "REGISTER":
      return ["Never load"]; // page reloads after this
    default:
      return state.filter(actionType => actionType !== action.type);
  }
};

export const rootReducer = combineReducers({
  tasks,
  templates,
  user,
  waitingFor,
  moods
});

const selectTasks = state => {
  return {
    ...Object.values(state.tasks).reduce(
      (mem, querySection) => ({
        ...mem,
        ...querySection
      }),
      {}
    ),
    ...state.tasks.overrides
  };
};

const taskCompletedToday = task => {
  const { finishedAt } = task;
  return isToday(finishedAt?.toDate ? finishedAt.toDate() : parse(finishedAt));
};

export const tasksIdsForList = listId => state => {
  const tasks = selectTasks(state);
  const taskIds = Object.keys(tasks);
  const filteredIds = taskIds.filter(taskId => {
    const task = tasks[taskId];
    const isThisList = task.status === listId;
    if (listId !== "DONE") {
      return isThisList;
    }
    return isThisList && taskCompletedToday(task);
  });
  return orderBy(
    filteredIds,
    id => {
      const { changedAt } = tasks[id];
      return changedAt?.toDate ? changedAt.toDate() : parse(changedAt);
    },
    "desc"
  );
};

export const selectUser = state => state.user;
export const selectUserId = state => selectUser(state).uid;
export const selectUserEmail = state => selectUser(state).email;
export const isLoggedIn = state => !!selectUserEmail(state);
export const isLoading = state => state.waitingFor.length > 0;

export const selectAllSuggestions = state =>
  Object.keys(selectTemplates(state)).map(key => {
    const template = selectTemplates(state)[key];
    return {
      templateId: key,
      label: template.title,
      ...template
    };
  });

const selectTemplates = state => ({ ...templatesData, ...state.templates });

export const selectHydratedTask = taskId => state => {
  const { task, template } = selectTaskAndTemplate(taskId)(state);
  return {
    ...template,
    ...task
  };
};

export const selectTask = taskId => state => {
  return selectTasks(state)[taskId];
};

export const selectTasksIdsInPlayForTemplate = templateId => state => {
  const tasks = selectTasks(state);
  const taskKeys = Object.keys(tasks);
  return taskKeys.filter(key => {
    const task = tasks[key];
    if (task.templateId === templateId) {
      const active =
        ["DOING", "NEXT", "TODAY", "LATER"].indexOf(task.status) > -1;
      const recentlyCompleted = taskCompletedToday(task);
      return active || recentlyCompleted;
    }
  });
};

export const selectTaskAndTemplate = taskId => state => {
  const task = selectTask(taskId)(state);
  const { templateId } = task;
  const template = selectTemplates(state)[templateId];
  return {
    task,
    template: template ? template : {}
  };
};

export const selectTemplateIdsWithCircle = circle => state => {
  const templates = selectTemplates(state);

  return Object.values(templates).reduce((mem, template) => {
    if (template.circles?.indexOf(circle) > -1) {
      return [...mem, template.id];
    }
    return mem;
  }, []);
};

export const tasksWithCircle = circle => state => {
  const tasks = selectTasks(state);
  return orderBy(
    Object.keys(tasks)
      .map(id => selectHydratedTask(id)(state))
      .filter(task => {
        return task.circles && task.circles.indexOf(circle) > -1;
      }),
    [
      task => {
        const { finishedAt } = task;
        return finishedAt ? finishedAt : "z";
      },
      "title"
    ],
    ["desc"]
  );
};

export const selectTaskStatus = taskId => state => {
  return selectTask(taskId)(state)?.status;
};
