import React from "react";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import { ListItem } from "../../shared/ExpandingRow";
import { Title } from "./styles";
import { selectHydratedTask } from "../../../reducers";
import { EditTaskDialogue } from "./EditTaskDialogue";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DeleteAction } from "./actions/ActionButtons";

const connectRedux = connect((state, { id }) => ({
  task: selectHydratedTask(id)(state)
}));

const Circles = styled.div`
  margin-top: 0.5rem;
  margin-left: -0.5rem;
`;

const Circle = styled(Chip)`
  && {
    font-size: 0.8rem;
    height: 1.5rem;
    margin: 0.25rem;
  }
`;

export const Details = connectRedux(({ id, task }) => (
  <ListItem>
    <Title>
      {task.description}
      {task.circles && (
        <Circles>
          {task.circles.map(circleId => (
            <Circle
              clickable={true}
              component={Link}
              to={`/circle/${circleId}`}
              key={circleId}
              label={circleId}
            />
          ))}
        </Circles>
      )}
    </Title>
    <React.Fragment>
      <EditTaskDialogue {...{ id }} />
      <DeleteAction {...{ id }} />
    </React.Fragment>
  </ListItem>
));
