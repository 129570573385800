import React from "react";

export const excludeKeys = (...propNames) => object => {
  return propNames.reduce((mem, name) => {
    const { [name]: removed, ...rest } = mem;
    return rest;
  }, object);
};

export const excludeProps = (...propNames) => Child => {
  return props => <Child {...excludeKeys(...propNames)(props)} />;
};
