import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { TodosPage } from "./components/todosPage/TodosPage";
import { AppBar } from "./components/layout/AppBar";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";
import createSagaMiddleware from "redux-saga";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CircleHistory } from "./components/circleHistory/circleHistory";
import { Login } from "./components/auth/Login";
import { Register } from "./components/auth/Register";
import styled, { createGlobalStyle } from "styled-components";
import { Loader } from "./components/shared/Loader";
import { MoodsHome } from "./components/moodsPage/MoodsHome";
import { history } from "./history";

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    background-color: #f0f0f0;
    height: 100%;
  }
`;

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

const PageContentWrapper = styled.div`
  padding: 0 1rem;
  padding-top: 54px;
  @media (min-width: 600px) {
    padding-top: 64px;
  }
`;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

const Page = () => (
  <Loader>
    <GlobalStyle />
    <AppBar />
    <PageContentWrapper>
      <Route path="/" exact component={TodosPage} />
      <Route path="/circle/:id" exact component={CircleHistory} />
      <Route path="/moods" component={MoodsHome} />
      <Route path="/login" exact component={Login} />
      <Route path="/register" exact component={Register} />
    </PageContentWrapper>
  </Loader>
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <Route component={Page} />
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
