import React, { Component } from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import { Divider } from "@material-ui/core";
import { excludeProps } from "../shared/excludeProps";
import { List } from "./List";
import { Head } from "./Head";

const Root = styled(excludeProps("hideOverflow")(Paper))`
  && {
    box-sizing: border-box;
    min-width: 80vw;

    @media (min-width: 500px) {
      min-width: 18rem;
    }

    display: flex;
    flex-direction: column;
    overflow-y: ${({ hideOverflow }) => (hideOverflow ? "visible" : "auto")};
  }
`;

export class Section extends Component {
  state = { showTaskInput: false };

  hideTaskInput = () => this.setState({ showTaskInput: false });
  toggleTaskInput = () =>
    this.setState(({ showTaskInput }) => ({
      showTaskInput: !showTaskInput
    }));

  render() {
    const { title, id } = this.props;
    const { showTaskInput } = this.state;
    return (
      <Root id={id} hideOverflow={showTaskInput} key={title}>
        <Head toggleTaskInput={this.toggleTaskInput} title={title} />
        <Divider />
        <List {...{ id, hideTaskInput: this.hideTaskInput, showTaskInput }} />
      </Root>
    );
  }
}
