import React, { Component } from "react";
import styled from "styled-components";
import { Section } from "./Section";

const Board = styled.div`
  background-color: inherit;
  padding-top: 1em;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 54px;
  @media (min-width: 600px) {
    top: 64px;
  }
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  &:before,
  &:after {
    content: "";
    width: 10px;
  }
  padding-bottom: 1rem;
`;

const lists = [
  {
    id: "DONE",
    title: "Done"
  },
  {
    id: "DOING",
    title: "Doing"
  },
  {
    id: "NEXT",
    title: "Next"
  },
  {
    id: "TODAY",
    title: "Today"
  },
  {
    id: "LATER",
    title: "Later"
  }
];

class TodosPage extends Component {
  render() {
    return (
      <Board>
        {lists.map(section => (
          <Section {...section} key={section.id} />
        ))}
      </Board>
    );
  }
}

export { TodosPage };
