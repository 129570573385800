import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import MuiPaper from "@material-ui/core/Paper";
import { ActionButton } from "./styles";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { connect } from "react-redux";
import { selectTaskAndTemplate } from "../../../reducers";
import { excludeKeys } from "../../shared/excludeProps";
import { EditTask } from "./edit/EditTask";

const TextArea = styled(TextField).attrs({ multiline: true })`
  && {
    width: 100%;
    max-width: 720px;
  }
`;

const Paper = styled(MuiPaper)`
  && {
    width: 100%;
    max-width: 480px;
  }
`;

const transformPropertyToDate = (obj, key) => {
  if (obj && obj[key]) {
    obj[key] = displayDate(obj[key]);
  }
};

const displayDate = date => (date.toDate ? date.toDate() : date);

const getEditableStringForObject = obj => {
  const dateProps = ["finishedAt", "changedAt", "createdAt"];
  dateProps.forEach(prop => transformPropertyToDate(obj, prop));
  const editableFields = excludeKeys("meta")(obj);
  return JSON.stringify(editableFields, null, 4);
};

class EditTaskDialogue extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = e => {
    e.preventDefault();
    const data = {
      task: JSON.parse(e.target.task.value),
      template: JSON.parse(e.target.template.value)
    };
    this.props.requestUpdates(data);
    this.handleClose();
  };

  render() {
    const { task, template } = this.props;
    return (
      <div>
        <ActionButton onClick={this.handleClickOpen} aria-label="Edit">
          <EditIcon />
        </ActionButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          PaperComponent={Paper}
          aria-labelledby="task-title"
        >
          <DialogTitle id="task-title">
            {task.title || template.title}
          </DialogTitle>
          <DialogContent style={{ overflowY: "visible" }}>
            <EditTask task={task} template={template} />
          </DialogContent>
          <form onSubmit={this.handleSubmit}>
            <DialogContent>
              <TextArea
                name="task"
                label="Task"
                defaultValue={getEditableStringForObject(task)}
              />
              <TextArea
                name="template"
                label="Template"
                defaultValue={getEditableStringForObject(template)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

const connectRedux = connect(
  (state, { id }) => selectTaskAndTemplate(id),
  {
    requestUpdates: data => ({ type: "REQUEST_UPDATES", data })
  }
);

EditTaskDialogue = connectRedux(EditTaskDialogue);

export { EditTaskDialogue };
