import { all, put, takeEvery } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import firebase from "firebase/app";

const defaultConfig = {
  onLoginSuccess: "/", // or saga effect? pass user details as callback?
  onRegisterSuccess: "/", // or saga effect? pass user details as callback?
  onLogoutSuccess: "/login",
  newUserCallback: user => put({ type: "NEW_USER", user })
};

const authentication = function*(config) {
  config = Object.assign(defaultConfig, config);
  const loginFirebaseEmailUser = ({ email, password }) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const login = function*({ loginDetails }) {
    try {
      yield loginFirebaseEmailUser(loginDetails);
      yield (window.location = config.onLoginSuccess);
    } catch (e) {
      yield put({ type: "FORM_FAIL", formError: e });
    }
  };

  const logoutFirebase = () => firebase.auth().signOut();

  const logout = function*() {
    yield logoutFirebase();
    yield (window.location = config.onLoginSuccess);
  };

  const registerFirebaseEmailUser = ({ email, password }) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const sanitizeUser = ({ displayName, email, emailVerified, uid }) => ({
    displayName,
    email,
    emailVerified,
    uid
  });

  const register = function*({ loginDetails }) {
    try {
      yield registerFirebaseEmailUser(loginDetails);
      yield (window.location = config.onRegisterSuccess);
      let user = firebase.auth().currentUser;
      yield firebase.auth().currentUser.updateProfile({
        displayName: loginDetails.displayName || loginDetails.email
      });

      yield config.newUserCallback(sanitizeUser(user));
    } catch (e) {
      yield put({ type: "FORM_FAIL", formError: e });
    }
  };

  const authStateChange = () =>
    eventChannel(emit =>
      firebase.auth().onAuthStateChanged(user => emit({ user }))
    );

  const handleAuthStateChange = function*({ user }) {
    if (user) {
      yield put({ type: "USER_FOUND", user: sanitizeUser(user) });
    } else {
      yield put({ type: "NO_USER_FOUND" });
      try {
        firebase.auth().signInAnonymously();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const watchAuthState = function*() {
    yield takeEvery(authStateChange(), handleAuthStateChange);
  };

  yield all([
    takeEvery("LOGIN", login),
    takeEvery("LOGOUT", logout),
    takeEvery("REGISTER", register),
    watchAuthState()
  ]);
};

export { authentication };
