import React, { useState, Fragment } from "react";
import { Link, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import styled from "styled-components";
import { Fab, Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { history } from "../../history";
import orderby from "lodash.orderby";
import format from "date-fns/format"

const Root = styled.div`
  height: 100%;
`;

const AddFab = styled(Fab)`
  && {
    margin-left: 1rem;
  }
`;

const Labels = styled.div`
  height: 1.5rem;
  width: 100%;
`;

const LeftLabel = styled.div`
  float: left;
`;

const RightLabel = styled.div`
  float: right;
`;

const MoodInputRoot = styled.div`
  max-width: 30rem;
  text-align: center;
`;

const MoodValueButton = styled(Button)`
  && {
    min-width: 20%;
    border-radius: 0;
    & + & {
      border-left: solid 1px grey;
    }
  }
`;

const MoodSkipButton = styled(Button)`
  && {
    margin-top: 0.5rem;
    min-width: 20%;
    border-radius: 0;
  }
`;

const MoodInput = ({ onClick, labels: { main, low, high } }) => {

  const options = [1,2,3,4,5];
  return (
    <MoodInputRoot>
      {main}
      <Labels><LeftLabel>{low}</LeftLabel> <RightLabel>{high}</RightLabel></Labels>
      
      {options.map(value =>(
          <MoodValueButton onClick={() => onClick(value)} aria-label={`mood value ${value}`}>{value}</MoodValueButton>
        )
      )}
      <div>
        <MoodSkipButton onClick={() => onClick()} aria-label={`skip`}>Skip</MoodSkipButton>
      </div>
    </MoodInputRoot>
  );
}

const defaultMoodMetrics = [
  {
    id: "general",
    labels: {
      main: "General",
      low: "bad",
      high: "good"
    }
  },
  {
    id: "confidence",
    labels: {
      main: "Confidence",
      low: "unsure",
      high: "confident"
    }
  },
  {
    id: "energy",
    labels: {
      main: "Energy level",
      low: "lethargic",
      high: "energetic"
    }
  },
  {
    id: "calmness",
    labels: {
      main: "Calmness",
      low: "agitated",
      high: "tranquil"
    }
  },
  {
    id: "hope",
    labels: {
      main: "Hopefulness",
      low: "hopeless",
      high: "hopeful"
    }
  },
  {
    id: "pain",
    labels: {
      main: "Pain",
      low: "bad pain",
      high: "pain free"
    }
  }
];

const AddMoods = connect(null, {
  postMoodsSet: (set) => ({
    type: "POST_MOODS_SET_REQUEST",
    set
  })
})(({ postMoodsSet }) => {
  const [currentIndex, setMood] = useState(0);
  const [moodMetrics, setMoodMetrics] = useState(defaultMoodMetrics);

  const currentMood = moodMetrics[currentIndex];

  if (!currentMood) {
    const onlyAnswered = moodMetrics.filter(m => !!m.value)
    postMoodsSet(onlyAnswered);

    return <Redirect to='/moods' />;
  }

  const onClick = (value) => {
    if (!value) {
      const newMoods = moodMetrics.filter(m => m.id !== currentMood.id);
      return setMoodMetrics(newMoods);
    }
    currentMood.value = value;
    setMoodMetrics(moodMetrics);
    setMood(index => ++index);
  };

  return <MoodInput {...{onClick, ...currentMood}}/>;
});

const MoodsList = connect(state=>({
  moodsSets: state.moods.moodsSets
}))(({ moodsSets }) => {
  return  moodsSets && orderby(moodsSets.filter(m=>m.createdAt), ["createdAt"], ["desc"]).map(({createdAt, set}) => (
    <div key={createdAt}>
      {typeof createdAt === "string" && <h3>{format(createdAt, "HH:mm D/M")}</h3>}
      {set.map(({ value, labels: { low, high, main } } ) => (
        <div key={createdAt+low+high}>
          {  main || `${low} / ${ high }` }: { value }
        </div>
      ))}
      </div>
  ));
});

export const MoodsHome = () => {
  return (
    <Root>
      <h1>
        Moods
        <Route path="/moods" exact component={() => (
          <Fragment>
            <AddFab color="primary" aria-label="Add entry" component={Link} to="/moods/add">
              <Add />
            </AddFab>
            <MoodsList/>
          </Fragment>
        )} />
      </h1>
      <Route path="/moods/add" exact component={AddMoods} />
    </Root>
  );
};
