/* eslint-disable */
import firebase from "firebase/app";
import { all } from "redux-saga/effects";
import flatten from "lodash.flatten";
import { templatesData } from "../reducers/templates";

const getNormalisedDataFromSnapshot = snap =>
  snap.docs.reduce(
    (mem, snap) => ({ ...mem, [snap.id]: { id: snap.id, ...snap.data() } }),
    {}
  );

const getArrayDataFromSnapshot = snap =>
  snap.docs.map(snap => ({ data: { id: snap.id, ...snap.data() }, snap }));

const oldId = "WvHbAkSbH5XodrhjHxmLvIFjGLQ2";
const newId = "3cyV683C3RUDQfRyqbUG8VRd3Uw1";

const getOldTasks = function*() {
  const tasksCollection = yield firebase
    .firestore()
    .collection("tasks")
    .where("userId", "==", oldId)
    .get();

  const tasks = getArrayDataFromSnapshot(tasksCollection);
  console.log(tasks);
  return tasks;
};
const getOldTemplates = function*() {
  const templatesCollection = yield firebase
    .firestore()
    .collection("templates")
    .where("userId", "==", oldId)
    .get();

  const templates = getArrayDataFromSnapshot(templatesCollection);
  console.log(templates);
  return templates;
};

const copyTasks = function*() {
  let tasksCollection = [{ data: { id: "test", value: "test" } }];
  tasksCollection = yield getOldTasks();

  let templatesCollection = [];
  templatesCollection = yield getOldTemplates();

  const newDb = window.firebase.prod.firestore();
  const batch = newDb.batch();
  const batch2 = newDb.batch();
  const templatesRef = newDb.collection("templates");
  const tasksRef = newDb.collection("tasks");

  const tasksCollection1 = tasksCollection.slice(0, 490);
  const tasksCollection2 = tasksCollection.slice(490);

  console.log(tasksCollection, tasksCollection1, tasksCollection2);

  tasksCollection1.forEach(({ data }) => {
    data = {
      ...data,
      userId: newId
    };
    batch.set(tasksRef.doc(data.id), data);
  });
  tasksCollection2.forEach(({ data }) => {
    data = {
      ...data,
      userId: newId
    };
    batch2.set(tasksRef.doc(data.id), data);
  });
  templatesCollection.forEach(({ data }) => {
    data = {
      ...data,
      userId: newId
    };
    batch2.set(templatesRef.doc(data.id), data);
  });

  //batch.commit();
  //batch2.commit();
};

const editAllTemplates = function*() {
  const db = firebase.firestore();
  const snaps = yield db.collection("templates").get();
  const templates = getArrayDataFromSnapshot(snaps);
  const myUserId = "";

  templates.forEach(template => {
    template.snap.ref.set({ userId: myUserId }, { merge: true });
  });
};

export const runMigrations = function*(userId) {
  //yield copyTasks();
};
