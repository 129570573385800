const templatesBase = {
  bins: {
    title: "Bins",
    description: "Take out the bins",
    circles: ["bins", "chore", "maintainence", "home"]
  },
  washingMachineRunClothes: {
    title: "Washing: Clothes",
    description: "Wash clothes",
    circles: [
      "washing",
      "washingMachineRun",
      "washingMachineRunClothes",
      "chore",
      "maintainence",
      "home"
    ]
  },
  washingMachineRunSheets: {
    title: "Washing: Sheets",
    description: "Wash sheets",
    circles: [
      "washing",
      "washingMachineRun",
      "washingMachineRunSheets",
      "chore",
      "maintainence",
      "home"
    ]
  },
  washingHang: {
    title: "Washing: hang",
    description: "Hang washing to dry",
    circles: ["washing", "washingHang", "chore", "maintainence", "home"]
  },
  washingPutAway: {
    title: "Washing: put away",
    description: "Put washing away",
    circles: ["washing", "washingPutAway", "chore", "maintainence", "home"]
  },
  sheetsChange: {
    title: "Sheets: change",
    description: "Change bedsheets",
    circles: ["washing", "sheetsChange", "chore", "maintainence", "home"]
  },
  vacuum: {
    title: "Vacuum",
    description: "Vacuum carpets",
    circles: ["vacuum", "chore", "maintainence", "home"]
  },
  dishwasherRun: {
    title: "Dishwasher: Run",
    description: "Run the dishwasher",
    circles: ["dishwasher", "dishwasherRun", "chore", "maintainence", "home"]
  },
  dishwasherEmpty: {
    title: "Dishwasher: Empty",
    description: "Empty the dishwasher",
    circles: [
      "dishwasher",
      "dishwasherEmpty",
      "chore",
      "tidying",
      "maintainence",
      "home"
    ]
  },
  orderGroceries: {
    title: "Groceries: Order",
    description: "Order groceries",
    circles: ["groceries", "orderGroceries", "chore", "home"],
    link: "http://anventory.herokuapp.com/"
  },
  readHowToComeAlive: {
    title: "Read",
    description: "Read something",
    circles: ["reading", "selfImprovement", "mentalHealth"]
  },
  meterReadings: {
    title: "Meter readings",
    description: "Get meter readings",
    circles: ["meterReadings", "chore", "home", "bills", "utilities"]
  },
  cleanKitchen: {
    title: "Clean: Kitchen",
    description: "Clean the kitchen",
    circles: ["cleanKitchen", "cleaning", "kitchen", "chore", "home"]
  },
  cleanBathroom: {
    title: "Clean: Bathroom",
    description: "Clean the Bathroom",
    circles: ["cleanBathroom", "cleaning", "bathroom", "chore", "home"]
  },
  tidyLivingRoom: {
    title: "Tidy: Living room",
    description: "Tidy the living room",
    circles: [
      "tidyLivingRoom",
      "cleaning",
      "tidying",
      "livingRoom",
      "chore",
      "home"
    ]
  },
  collectPost: {
    title: "Collect post",
    description: "Collect letters from letterbox",
    circles: ["post", "collectPost", "chore", "home"]
  },
  waterPlant: {
    title: "Water plant",
    description: "... water the plant",
    circles: ["waterPlant", "plants", "livingRoom", "chore", "home"]
  },
  run: {
    title: "Run",
    description: "Go for a run",
    circles: ["run", "exercise", "selfImprovement", "health"]
  },
  cookBatch: {
    title: "Cook: Batch",
    description: "Cook a batch of a meal",
    customFields: {
      meal: { title: "Meal", value: "?" }
    },
    circles: [
      "cook",
      "cookBatch",
      "foodAndDrink",
      "food",
      "selfImprovement",
      "health"
    ]
  },
  filePapers: {
    title: "File papers",
    description: "File away papers",
    circles: ["filePapers", "maintainence", "chore", "home"]
  }
};

export const templatesData = Object.keys(templatesBase).reduce(
  (mem, id) => ({
    ...mem,
    [id]: {
      id,
      ...templatesBase[id]
    }
  }),
  templatesBase
);
