import React from "react";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { ActionButton } from "../styles";

const mapObject = func => obj =>
  Object.keys(obj).reduce(
    (mem, key) => ({
      ...mem,
      [key]: func(obj[key])
    }),
    {}
  );

const connectDispatchByProps = actions =>
  connect(
    null,
    (dispatch, props) =>
      mapObject(actionCreator => () => dispatch(actionCreator(props)))(actions)
  );

const createActionButton = ({ Icon, label, ...rest }) => (
  <ActionButton {...rest} aria-label={label}>
    <Icon />
  </ActionButton>
);

const DispatchActionButton = ({ action, ...rest }) => {
  const connectRedux = connectDispatchByProps({ action });
  const Component = connectRedux(({ action }) =>
    createActionButton({ onClick: action, ...rest })
  );
  return <Component {...rest} />;
};

const taskActionCreator = type => ({ id }) => ({ type, id });

export const DeleteAction = props => (
  <DispatchActionButton
    {...{
      ...props,
      action: taskActionCreator("DELETE_TASK"),
      Icon: DeleteIcon,
      label: "Delete"
    }}
  />
);

export const DoneAction = props => (
  <DispatchActionButton
    {...{
      ...props,
      action: taskActionCreator("MOVE_TO_DONE"),
      Icon: DoneIcon,
      label: "Done"
    }}
  />
);

export const MoveLeftAction = props => (
  <DispatchActionButton
    {...{
      ...props,
      action: taskActionCreator("MOVE_LEFT"),
      Icon: KeyboardArrowLeft,
      label: "Left"
    }}
  />
);

export const MoveRightAction = props => (
  <DispatchActionButton
    {...{
      ...props,
      action: taskActionCreator("MOVE_RIGHT"),
      Icon: KeyboardArrowRight,
      label: "Right"
    }}
  />
);
