import React from "react";
import { Collapse } from "@material-ui/core";
import { withState } from "recompose";
import styled from "styled-components";
import MuiListItem from "@material-ui/core/ListItem";

export const ListItem = styled(MuiListItem)`
  && {
    display: flex;
  }
`;

export const ExpandingRow = withState("isExpanded", "setIsExpanded", false)(
  ({ setIsExpanded, isExpanded, children, details }) => (
    <React.Fragment>
      <ListItem button onClick={() => setIsExpanded(e => !e)}>
        {children({ isOpen: isExpanded })}
      </ListItem>
      <Collapse in={isExpanded}>{details}</Collapse>
    </React.Fragment>
  )
);
