import MuiIconButton from "@material-ui/core/IconButton";
import styled from "styled-components";

export const Title = styled.div`
  flex: 1 1 auto;
`;

export const ActionButton = styled(MuiIconButton)`
  && {
    @media (min-width: 500px) {
      padding: 0;
    }
  }
`;
