import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { isLoggedIn } from "../../reducers";
import { Link } from "react-router-dom";
import styled from "styled-components";

const AppBarButton = styled(Button)`
  && {
    color: white;
  }
`;

export const AuthActions = connect(
  state => ({
    isLoggedIn: isLoggedIn(state)
  }),
  {
    logout: () => ({ type: "LOGOUT" })
  }
)(({ isLoggedIn, logout }) => (
  <Fragment>
    {isLoggedIn && <AppBarButton onClick={logout}>Logout</AppBarButton>}
    {!isLoggedIn && (
      <Fragment>
        <AppBarButton component={Link} to={"/login"}>
          Login
        </AppBarButton>
        <AppBarButton component={Link} to={"/register"}>
          Register
        </AppBarButton>
      </Fragment>
    )}
  </Fragment>
));
