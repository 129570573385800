import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "react-select/lib/AsyncCreatable";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { selectAllSuggestions } from "../../../reducers";
import Fuse from "fuse.js";
import styled from "styled-components";
import uniq from "uniqid";

const createSuggestions = allSuggestions => inputValue => {
  const fuse = new Fuse(allSuggestions, {
    shouldSort: true,
    includeScore: true,
    threshold: 0.3,
    keys: [
      { name: "title", weight: 1 },
      { name: "description", weight: 0.6 },
      { name: "circles", weight: 0.4 },
      { name: "link", weight: 0.1 }
    ]
  });
  return new Promise(resolve => {
    if (inputValue?.length <= 0) {
      return resolve(allSuggestions);
    }
    const resultsWithScore = fuse.search(inputValue);
    const results = resultsWithScore.map(r => ({ ...r.item, value: uniq() }));
    return resolve(results);
  }).catch(console.log);
};

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    display: "flex",
    padding: 0,
    height: "auto"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    margin: "0.6em 0 0.7em 0.6em"
  },
  chip: {
    margin: `5px 2.5px`
  },
  chipFocused: {
    backgroundColor: emphasize("#CCC", 0.08)
  },
  noOptionsMessage: {
    padding: `10px 20px`
  },
  selectedValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0
  }
}));

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          "aria-label": "Enter task name",
          ...props.innerProps,
          contentEditable: true,
          suppressContentEditableWarning: true
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

const Circles = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 500px) {
    ${({ isFocused }) => (isFocused ? "" : "display: none;")}
  }
`;

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        height: "auto",
        display: "block"
      }}
      {...props.innerProps}
    >
      {props.children}
      {props.data.circles && (
        <Circles isFocused={props.isFocused}>
          {props.data.circles.join(", ")}
        </Circles>
      )}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SelectedValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.selectedValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  Option,
  Placeholder,
  SelectedValue,
  ValueContainer
};

function IntegrationReactSelect({ allSuggestions, inputRef, leave, submit }) {
  const classes = useStyles();

  function handleChange(value) {
    submit(value);
    leave();
  }

  const selectStyles = {
    input: base => ({
      ...base,
      "& input": {
        font: "inherit"
      }
    })
  };

  return (
    <div className={classes.root}>
      <Select
        defaultOptions={true}
        ref={inputRef}
        classes={classes}
        styles={selectStyles}
        loadOptions={createSuggestions(allSuggestions)}
        components={components}
        menuIsOpen={true}
        onChange={handleChange}
        placeholder=""
      />
    </div>
  );
}

const mapState = state => ({
  allSuggestions: selectAllSuggestions(state)
});

export const TaskInput = connect(mapState)(IntegrationReactSelect);
