import { initialiseRSFirebase } from "../redux-saga-firebase";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { authentication, dispatchOnValueChange } from "../redux-saga-firebase";
import { runMigrations } from "./migrations";
import {
  selectUserId,
  selectTemplateIdsWithCircle,
  selectTaskStatus,
  selectTask
} from "../reducers";
import { jobs } from "./tasks";
import { subHours } from "date-fns";
import {
  createUserTasksCollectionQuery,
  createTaskRef,
  createUserTemplatesCollectionQuery,
  createTemplateRef,
  createRecentlyFinishedTasksRef,
  createUnfinishedTasksRef
} from "./refs";
import { moodSagas } from "./moods";

const config = process.env.REACT_APP_FIREBASE_CONFIG;
const firebaseConfig = JSON.parse(config);

const everyUserFound = (...effects) =>
  all(effects.map(e => takeEvery("USER_FOUND", e)));

const postTask = function*(newTask) {
  const userId = yield select(selectUserId);
  const newTaskDoc = createTaskRef();
  const createdAt = new Date().toISOString();
  newTask = {
    id: newTaskDoc.id,
    userId,
    ...newTask,
    changedAt: createdAt,
    createdAt,
    finishedAt: newTask.status === "DONE" ? createdAt : null
  };
  yield put({ type: "ADD_TASK", task: newTask });
  yield newTaskDoc.set(newTask);
};

const postTemplate = function*(newTemplate) {
  const userId = yield select(selectUserId);
  const newTemplateDoc = createTemplateRef();
  const createdAt = new Date().toISOString();
  newTemplate = {
    id: newTemplateDoc.id,
    userId,
    circles: [],
    ...newTemplate,
    changedAt: createdAt,
    createdAt
  };
  yield put({ type: "ADD_TEMPLATE", template: newTemplate });
  yield newTemplateDoc.set(newTemplate);
  return newTemplate;
};

const addTask = function*({ newTask }) {
  if (newTask.templateId) {
    return yield postTask(newTask);
  }
  const { status, title } = newTask;
  const templateFields = {
    title
  };
  const template = yield postTemplate(templateFields);
  const taskFields = {
    status,
    templateId: template.id
  };
  yield postTask(taskFields);
};

const updateTask = function*(userId, task) {
  const updatedTaskRef = createTaskRef(task.id);
  const changedAt = new Date().toISOString();
  const updatedTask = {
    ...task,
    changedAt
  };
  yield put({ type: "UPDATE_TASK", task: updatedTask });
  yield updatedTaskRef.set(updatedTask, { merge: true });
};

const updateTemplate = function*(userId, template) {
  const updatedTemplateRef = createTemplateRef(template.id);
  const changedAt = new Date().toISOString();
  const updatedTemplate = {
    ...template,
    changedAt,
    userId
  };
  yield put({ type: "UPDATE_TEMPLATE", task: updatedTemplate });
  yield updatedTemplateRef.set(updatedTemplate, { merge: true });
};

const requestUpdates = function*({ data: { task, template } }) {
  const userId = yield select(selectUserId);
  yield updateTask(userId, task);
  yield updateTemplate(userId, template);
};

const moveLeftStatusMap = {
  DONE: "DONE",
  DOING: "DONE",
  NEXT: "DOING",
  TODAY: "NEXT",
  LATER: "TODAY"
};

const moveRightStatusMap = {
  DONE: "DOING",
  DOING: "NEXT",
  NEXT: "TODAY",
  TODAY: "LATER",
  LATER: "EVEN_LATER"
};

const moveTaskTo = nextStatus =>
  function*({ id }) {
    const updatedTaskRef = createTaskRef(id);
    const changedAt = new Date().toISOString();
    const updatedTask = {
      id: updatedTaskRef.id,
      status: nextStatus,
      changedAt,
      finishedAt: nextStatus === "DONE" ? changedAt : null
    };
    yield put({ type: "UPDATE_TASK", task: updatedTask });
    yield updatedTaskRef.set(updatedTask, { merge: true });
  };

const moveTaskDirection = direction =>
  function*({ id }) {
    const currentStatus = yield select(selectTaskStatus(id));
    const nextStatus =
      direction === "LEFT"
        ? moveLeftStatusMap[currentStatus]
        : moveRightStatusMap[currentStatus];
    yield moveTaskTo(nextStatus)({ id });
  };

const deleteTask = function*({ id }) {
  const ref = createTaskRef(id);
  yield ref.delete();
};

const queries = { templateId: [] };
const getTasksInCircle = function*({ circleId }) {
  const userId = yield select(selectUserId);
  const tasksRef = createUserTasksCollectionQuery(userId);

  const templateIds = yield select(selectTemplateIdsWithCircle(circleId));

  const newTemplateIds = templateIds.filter(
    id => queries.templateId.indexOf(id) === -1
  );

  yield all(
    newTemplateIds.map(templateId => {
      const query = ["templateId", "==", templateId];
      const tasksInCircle = tasksRef.where(...query);
      queries.templateId = [...queries.templateId, templateId];
      return dispatchOnValueChange(tasksInCircle, tasks => ({
        type: "GET_TASKS_RESPONSE",
        query: query.join(" "),
        tasks,
        templateId
      }));
    })
  );
};

const taskQueries = function*() {
  const userId = yield select(selectUserId);
  const unfinishedTasks = createUnfinishedTasksRef(userId);
  const recentlyFinishedTasks = createRecentlyFinishedTasksRef(userId);
  yield all([
    dispatchOnValueChange(recentlyFinishedTasks, tasks => ({
      type: "GET_TASKS_RESPONSE",
      query: "RECENTLY_FINISHED",
      tasks
    })),
    dispatchOnValueChange(unfinishedTasks, tasks => ({
      type: "GET_TASKS_RESPONSE",
      query: "UNFINISHED",
      tasks
    })),
    takeEvery("GET_TASKS_IN_CIRCLE", getTasksInCircle)
  ]);
};

const setRepeat = function*({ data: { templateId, repeat } }) {
  const updatedTemplateRef = createTemplateRef(templateId);
  yield updatedTemplateRef.set({ repeat }, { merge: true });
};

const subtractHoursFromFinishedAt = function*({ taskId, hours }) {
  const updatedTemplateRef = createTaskRef(taskId);
  const task = yield select(selectTask(taskId));
  const finishedAt = subHours(task.finishedAt, hours).toISOString();
  yield updatedTemplateRef.set({ finishedAt }, { merge: true });
};

const userDependantEffects = function*() {
  const userId = yield select(selectUserId);
  const templatesRef = createUserTemplatesCollectionQuery(userId);
  yield all([
    taskQueries(),
    dispatchOnValueChange(templatesRef, templates => ({
      type: "GET_TEMPLATES_RESPONSE",
      templates
    })),
    takeEvery("REQUEST_ADD_TASK", addTask),
    takeEvery("DELETE_TASK", deleteTask),
    takeEvery("MOVE_LEFT", moveTaskDirection("LEFT")),
    takeEvery("MOVE_RIGHT", moveTaskDirection("RIGHT")),
    takeEvery("MOVE_TO_DONE", moveTaskTo("DONE")),
    takeEvery("REQUEST_UPDATES", requestUpdates),
    takeEvery("SET_REPEAT", setRepeat),
    takeEvery("FINISHED_AT_SUBTRACT", subtractHoursFromFinishedAt),
    all(moodSagas),
    jobs()
  ]);
};

export const rootSaga = function*() {
  yield initialiseRSFirebase({ firebaseConfig });

  yield everyUserFound(userDependantEffects);

  yield runMigrations();

  yield authentication({});
};
