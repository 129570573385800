import React, { Component } from "react";
import styled from "styled-components";
import MuiList from "@material-ui/core/List";
import { TaskListItem } from "./taskListItem/TaskListItem";
import { connect } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import { tasksIdsForList } from "../../reducers";
import { TaskInput } from "./addTask/AutoComplete";
import { excludeProps } from "../shared/excludeProps";

const excludeHideOverflow = excludeProps("hideOverflow");

const TaskList = styled(excludeHideOverflow(MuiList))`
  && {
    overflow-y: ${({ hideOverflow }) => (hideOverflow ? "visible" : "auto")};
    height: 100%;
    padding-top: 0;
  }
`;

class FocusedTaskInput extends Component {
  componentDidMount = () => this.inputRef.focus();

  setRef = ref => (this.inputRef = ref);

  render() {
    const { leave, submit } = this.props;
    return (
      <TaskInput
        onKeyDown={e => {
          const ENTER_KEY_CODE = 13;
          const ESC_KEY_CODE = 27;
          if (e.keyCode === ENTER_KEY_CODE) {
            submit(e.target.value);
            this.inputRef.value = "";
          } else if (e.keyCode === ESC_KEY_CODE) {
            leave();
            this.inputRef.value = "";
          }
        }}
        inputRef={this.setRef}
        {...this.props}
      />
    );
  }
}

class _List extends Component {
  render() {
    const {
      addTask,
      hideTaskInput,
      showTaskInput,
      tasks,
      id: listId
    } = this.props;
    return (
      <TaskList hideOverflow={showTaskInput}>
        {showTaskInput && (
          <ClickAwayListener onClickAway={hideTaskInput}>
            <FocusedTaskInput
              submit={addTask}
              leave={hideTaskInput}
              placeholder="New task..."
            />
          </ClickAwayListener>
        )}
        {tasks.map(id => (
          <TaskListItem {...{ id, key: id, listId }} />
        ))}
      </TaskList>
    );
  }
}

export const List = connect(
  (state, { id }) => ({
    tasks: tasksIdsForList(id)(state)
  }),
  (dispatch, { id: listId }) => ({
    addTask: ({ label, templateId, __isNew__, ...rest }) => {
      // send all to saga - handle data there
      return dispatch({
        type: "REQUEST_ADD_TASK",
        newTask: {
          ...(templateId
            ? { status: listId, templateId }
            : { status: listId, title: label, ...rest })
        }
      });
    }
  })
)(_List);
