import { cancel, put, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

function* cancelSaga(task) {
  yield cancel(task);
}

const nextTick = func => Promise.resolve().then(func);

export const mapFirestoreQueryResponse = snapshot => {
  return snapshot.docs.reduce(
    (mem, d) => ({
      ...mem,
      [d.id]: {
        id: d.id,
        ...d.data(),
        meta: { unconfirmed: d._hasPendingWrites }
      }
    }),
    {}
  );
};

const handleChange = actionCreator =>
  function*({ snapshot }) {
    const value = mapFirestoreQueryResponse(snapshot);
    yield put(actionCreator(value));
  };

const createValueChannel = ref =>
  eventChannel(emit => {
    return ref.onSnapshot({ includeMetadataChanges: true }, snapshot =>
      nextTick(() => emit({ snapshot }))
    );
  });

const cancelOnLogout = (cancelOn, task) =>
  takeLatest(cancelOn, cancelSaga, task);

const dispatchOnValueChange = function*(
  ref,
  actionCreator,
  { cancelOn = "LOGOUT" } = {}
) {
  try {
    const task = yield takeEvery(
      createValueChannel(ref),
      handleChange(actionCreator)
    );
    if (cancelOn) {
      yield cancelOnLogout(cancelOn, task);
    }
  } catch (e) {
    yield console.error(e);
  }
};

export { dispatchOnValueChange };
