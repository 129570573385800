import { select, all } from "redux-saga/effects";
import { listenOnPostMoodsRequest } from "./post";
import { dispatchOnValueChange } from "../../redux-saga-firebase";
import { createUserMoodsSetCollectionQuery } from "./refs";
import { selectUserId } from "../../reducers";

const createListeners = function* () {
    const userId = yield select(selectUserId);
    return yield all([
        dispatchOnValueChange(createUserMoodsSetCollectionQuery(userId), sets => ({
            type: "GET_MOOD_SETS_RESPONSE",
            sets: Object.values(sets)
          }))
    ]);
}

export const moodSagas = [
    listenOnPostMoodsRequest(),
    createListeners()
];