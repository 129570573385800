import { all, put, select, takeEvery } from "redux-saga/effects";
import { selectTasksIdsInPlayForTemplate, selectUserId } from "../reducers";
import {
  createUnfinishedTasksRef,
  createRecentlyFinishedTasksRef
} from "./refs";
import { mapFirestoreQueryResponse } from "../redux-saga-firebase";

const handleRepeat = function*(templateId) {
  const activeTasks = yield select(selectTasksIdsInPlayForTemplate(templateId));
  if (activeTasks[0]) {
    return;
  }
  yield put({
    type: "REQUEST_ADD_TASK",
    newTask: {
      status: "TODAY",
      templateId
    }
  });
};

const getLatestTasks = function*() {
  const userId = yield select(selectUserId);
  const unfinishedTasksRef = createUnfinishedTasksRef(userId);
  const recentlyFinishedTasksRef = createRecentlyFinishedTasksRef(userId);
  const [unfinishedTasks, recentlyFinishedTasks] = yield all(
    [unfinishedTasksRef, recentlyFinishedTasksRef].map(ref => {
      return ref.get();
    })
  );
  yield all([
    put({
      type: "GET_TASKS_RESPONSE",
      query: "RECENTLY_FINISHED",
      tasks: mapFirestoreQueryResponse(recentlyFinishedTasks)
    }),
    put({
      type: "GET_TASKS_RESPONSE",
      query: "UNFINISHED",
      tasks: mapFirestoreQueryResponse(unfinishedTasks)
    })
  ]);
};

const handleRepeats = function*({ templates }) {
  yield getLatestTasks();
  const templateIds = Object.keys(templates);
  yield all(
    templateIds.map(templateId => {
      const template = templates[templateId];
      if (template.repeat) {
        return handleRepeat(templateId);
      }
    })
  );
};

export const jobs = function*() {
  yield takeEvery("GET_TEMPLATES_RESPONSE", handleRepeats);
};
