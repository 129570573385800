import React from "react";
import styled from "styled-components";
import GoTo from "@material-ui/icons/ExitToApp";
import { connect } from "react-redux";
import { ListItem } from "../../shared/ExpandingRow";
import { Title, ActionButton } from "./styles";
import { selectHydratedTask } from "../../../reducers";
import {
  DoneAction,
  MoveLeftAction,
  MoveRightAction
} from "./actions/ActionButtons";

const Actions = styled.div`
  flex: 0 0 auto;

  @media (min-width: 500px) {
    ${ListItem} & {
      ${({ isOpen }) => (isOpen ? "" : "visibility: hidden;")}
    }

    ${ListItem}:hover & {
      visibility: visible;
    }
  }
`;

const connectRedux = connect((state, { id }) => selectHydratedTask(id));

export const Summary = connectRedux(({ id, isOpen, link, meta, title }) => {
  const isUnconfirmed = meta && meta.unconfirmed;
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Actions {...{ isOpen }}>
        {link && (
          <ActionButton
            onClick={e => e.stopPropagation()}
            href={link}
            rel="noopener noreferrer"
            target="_blank"
            component={"a"}
            aria-label="Go to external link"
          >
            <GoTo />
          </ActionButton>
        )}
        <DoneAction {...{ id }} disabled={isUnconfirmed} />
        <MoveLeftAction {...{ id }} disabled={isUnconfirmed} />
        <MoveRightAction {...{ id }} disabled={isUnconfirmed} />
      </Actions>
    </React.Fragment>
  );
});
