import React, { useEffect } from "react";
import { connect } from "react-redux";
import { tasksWithCircle } from "../../reducers";
import format from "date-fns/format";

const connectRedux = connect(
  (state, { match }) => ({
    tasks: tasksWithCircle(match.params.id)(state)
  }),
  {
    getTasksWithCircle: circleId => ({ type: "GET_TASKS_IN_CIRCLE", circleId })
  }
);

const getDate = date => (date.toDate ? date.toDate() : date);

export const CircleHistory = connectRedux(
  ({ match, tasks, getTasksWithCircle }) => {
    const { id } = match.params;
    useEffect(() => {
      getTasksWithCircle(id);
    }, []);
    return (
      <div>
        <h1>{id}</h1>
        <div>
          {tasks.map(task => (
            <div key={task.id}>
              {task.title} -{" "}
              {(task.finishedAt &&
                format(getDate(task.finishedAt), "HH:mm DD/M/YY")) ||
                task.status}
            </div>
          ))}
        </div>
      </div>
    );
  }
);
