import React from "react";
import { Divider } from "@material-ui/core";
import { ExpandingRow } from "../../shared/ExpandingRow";
import { Details } from "./Details";
import { Summary } from "./Summary";

const TaskListItem = ({ id, listId }) => (
  <React.Fragment>
    <ExpandingRow details={<Details {...{ id }} />}>
      {({ isOpen }) => <Summary {...{ id, isOpen, listId }} />}
    </ExpandingRow>
    <Divider />
  </React.Fragment>
);

export { TaskListItem };
