import firebaseApp from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";

const initialiseRSFirebase = function*(config, name) {
  const instance = yield firebaseApp.initializeApp(config.firebaseConfig, name);
  if (name) {
    window.firebase = { [name]: instance };
  }

  const perf = firebaseApp.performance();

  const trace = perf.trace("customTraceName");
  trace.start();

  trace.stop();
};

export { initialiseRSFirebase };
