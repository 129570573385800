import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { isLoading } from "../../reducers";

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoadingIndicatorPage = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
);

export const Loader = connect(state => ({ isLoading: isLoading(state) }))(
  ({ children, isLoading }) => (isLoading ? <LoadingIndicatorPage /> : children)
);
