import React from "react";
import styled from "styled-components";
import { Paper, TextField } from "@material-ui/core";

export const FieldDiv = styled.div`
  margin: 2rem;
  text-align: center;
`;

export const FormTextField = styled(TextField)`
  && {
    width: 15rem;
  }
`;

const AuthFormRoot = styled.div`
  background: inherit;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthFormWrapper = ({ children }) => (
  <AuthFormRoot>
    <Paper>{children}</Paper>
  </AuthFormRoot>
);
