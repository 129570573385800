import React, { Component } from "react";
import { connect } from "react-redux";
import { excludeKeys } from "../shared/excludeProps";

class Form extends Component {
  componentWillUnmount() {
    this.props.exitForm();
  }
  render() {
    return <form {...excludeKeys("exitForm")(this.props)} />;
  }
}

const dispatchActions = {
  exitForm: () => ({
    type: "EXIT_FORM"
  })
};

Form = connect(
  null,
  dispatchActions
)(Form);

export { Form };
