import React, { Fragment } from "react";
import { Button, Switch, FormControlLabel, FormLabel } from "@material-ui/core";
import { connect } from "react-redux";
import format from "date-fns/format";

const _RepeatTask = ({ setRepeat, task, template }) => (
  <FormControlLabel
    control={<Switch color="primary" value="repeat" name="repeat" />}
    label="Repeat daily"
    aria-label="Repeat"
    checked={!!template.repeat}
    onChange={e =>
      setRepeat({ templateId: template.id, repeat: e.target.checked })
    }
  />
);

const connectRedux = connect(
  null,
  {
    setRepeat: data => ({ type: "SET_REPEAT", data })
  }
);

const RepeatTask = connectRedux(_RepeatTask);

const _EditFinishedAt = ({ backupOneHour, task }) => (
  <div>
    <FormLabel aria-label="Finished at">
      Finished at: {format(task.finishedAt, "HH:mm")}
      <Button
        control={<Switch color="primary" value="repeat" name="repeat" />}
        label="Repeat daily"
        aria-label="Back up finished at by 1 hour"
        onClick={e => backupOneHour(task.id)}
      >
        Back one hour
      </Button>
    </FormLabel>
  </div>
);

const connectReduxFinishedAt = connect(
  null,
  {
    backupOneHour: taskId => ({
      type: "FINISHED_AT_SUBTRACT",
      hours: 1,
      taskId
    })
  }
);

const EditFinishedAt = connectReduxFinishedAt(_EditFinishedAt);

export const EditTask = props => (
  <Fragment>
    <RepeatTask {...props} />
    {props.task.finishedAt && <EditFinishedAt {...props} />}
  </Fragment>
);
