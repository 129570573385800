import { select, takeEvery } from "redux-saga/effects";
import { selectUserId } from "../../reducers";
import {
  createMoodsSetCollectionRef
} from "./refs";

const postMoodsSetRequest = function*({ set }) {
  const userId = yield select(selectUserId);
  const moodsSetRef = createMoodsSetCollectionRef();
  const createdAt = new Date().toISOString();
  yield moodsSetRef.add({
    createdAt,
    changedAt: createdAt,
    set,
    userId
  });
};

export const listenOnPostMoodsRequest = function*() {
  yield takeEvery("POST_MOODS_SET_REQUEST", postMoodsSetRequest);
};
